<template>
<div class="page_edit_position">
  <div class="inner" style="padding:50px;background-color:#fff;">
    <el-form :rules="rules" :model="info" ref="form">
      <div class="form_left">
        <div class="form_title">基本信息</div>
<!--        <el-form-item label="公司名称">-->
<!--          <el-input placeholder="请输入公司名称"/>-->
<!--        </el-form-item>-->
        <el-form-item label="职位名称" prop="postName">
          <el-input v-model="info.postName" placeholder="请输入职位名称"/>
        </el-form-item>
<!--        <el-form-item label="职位简介" prop="postBrief">-->
<!--          <el-input v-model="info.postBrief" placeholder="请输入职位简介"/>-->
<!--        </el-form-item>-->
        <el-form-item label="职位描述" prop="postDescription" style="align-items: flex-start">
<!--          <el-input v-model="info.postDescription" placeholder="请输入职位描述" type="textarea" :autosize="{minRows:3}"/>-->
          <div id="editor" style="width:100%;height:500px;"></div>
        </el-form-item>
        <el-form-item label="职位类型" prop="postClassificationId">
          <el-cascader
              v-if="loadComplete"
              style="width:100%;"
              :props="positionProp" v-model="position" :show-all-levels="false"></el-cascader>
        </el-form-item>
      </div>
      <div class="form_right">
        <div class="form_title">职位要求</div>
        <el-form-item label="经验" prop="workExperienceId">
          <el-select v-model="info.workExperienceId">
            <el-option v-for="item in experienceList"
                       :label="item.description"
                       :key="item.id"
                       :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学历" prop="education">
          <el-select v-model="info.education">
            <el-option
              v-for="item in userStore.degree"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <template #label>
            <el-switch
                v-model="writtenSalary"
                class="ml-2"
                inline-prompt
                style="--el-switch-on-color: #00b0b1; --el-switch-off-color: #505050"
                active-text="薪资范围"
                inactive-text="薪资面议"
            />

<!--            <el-switch-->
<!--                v-if="writtenSalary"-->
<!--                v-model="monthSalary"-->
<!--                class="ml-2"-->
<!--                inline-prompt-->
<!--                style="&#45;&#45;el-switch-on-color: #00b0b1; &#45;&#45;el-switch-off-color: #505050;margin-left:10px;"-->
<!--                active-text="月工资"-->
<!--                inactive-text="日工资"-->
<!--            />-->
          </template>
        </el-form-item>
        <el-form-item v-if="writtenSalary" label="薪资范围" prop="salLow"  style="align-items: flex-start;">
          <el-input type="number" placeholder="薪资下限(K)" v-model="info.salLow" min="0" class="salary_low"/>
          <div style="border-top:1px solid #000;width:10px;margin:0 5px;"></div>
          <el-input placeholder="薪资上限(K)" title="薪资上限" min="0" type="number" v-model="info.salHigh" class="salary_high"/>
          <div v-if="monthSalary" style="margin:0 5px;">*</div>
          <el-input v-if="monthSalary" placeholder="薪资总月数" min="0" v-model="info.salMonths" type="number" class="salary_month"/>

        </el-form-item>
        <div>
          <div class="el-form-item_label">关键词</div>
          <div class="el-form-item__content">
            <div class="job_tag" v-for="(val,index) in keywords" :key="val" @click="editTag(index)">{{val}}</div>
            <div class="job_tag job_tag_add" @click="editTag(-1)">+</div>
          </div>
        </div>
        <el-form-item label="工作城市" prop="provinceCode">
          <el-cascader
              style="width:100%;"
              :props="areaProp" v-model="cityCode"></el-cascader>
        </el-form-item>
        <el-form-item label="工作地点" prop="address">
          <el-input v-model="info.address"  type="textarea" :autosize="{minRows:3}" placeholder="请输入您的工作地点"/>
        </el-form-item>
      </div>
    </el-form>
    <div class="agree">
      <el-checkbox id="agree" :false-label="2" :true-label="1" v-model="info.agreeServiceAgreement"/>
      <div style="margin-left:20px;">
        <span>同意嵌入式</span>
        <a href="javascript:void(0)">《嵌入式用户服务协议》</a><span>及</span>
        <a href="javascript:void(0)">《个人信息保护政策》</a>
      </div>
    </div>
    <div class="button_group">
      <el-button type="primary" round @click="op('save')">保存</el-button>
      <el-button round style="color:#00b0b1" @click="op('cancel')">取消</el-button>
    </div>
  </div>

  <el-dialog v-model="showEditTag" title="编辑标签" width="30%" center :show-close="false">
    <el-input placeholder="输入标签" v-model="tag"/>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showEditTag = false">取消</el-button>
        <el-button v-show="selectedTag>-1" @click="delTag" style="background-color:#f44f44;color:#fff;">删除</el-button>
        <el-button type="primary" @click="editTagSure">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</div>
</template>

<script>
import {
  getPositionList,
  getWorkExperienceList,
  publishCompanyPost,
  getPostDetail,
  updateCompanyPost
} from "@/api/company/company";
import {getAreaList, getCityList, getProvinceList} from "@/api/user/area";
import { mapStores } from 'pinia';
import { useUserStore } from "@/store/user/user";
import {keywordSplit} from "@/util/util";
// import VueEditorWrap from "vue-ueditor-wrap"
export default {
  name: "editPosition",
  props:{
    item:{
      type:Object,
      default(){
        return {

        }
      }
    }
  },
  data(){
    let that = this;
    return {
      writtenSalary:false,//是否设置薪资 false:2
      monthSalary:true,//月付1 日付3
      showEditTag:false,
      selectedTag:-1,
      tag:"",
      cityCode:[],
      keywords:[],
      info:{
        "address": "",
        "agreeServiceAgreement": 0,
        "areaCode": "",
        "cityCode": "",
        "education": "",
        "keyWords": "",
        "postBrief": "",
        "postClassificationId": "",
        "postDescription": "",
        "postName": "",
        "provinceCode": "",
        "salType":1,
        "salHigh": '',
        "salLow": '',
        "salMonths": '',
        "workExperienceId": ""
      },
      rules:{
        postName:{
          trigger:"blur",
          required:true,
          message:"请输入职位"
        },
        postDescription:{
          trigger:"blur",
          required:true,
          message:"请输入职位描述"
        },
        // postBrief:{
        //   trigger:"blur",
        //   required:true,
        //   message:"请输入职位简介"
        // },
        postClassificationId:{
          trigger:"blur",
          required:true,
          message:"请选择职位类别"
        },
        workExperienceId:{
          trigger:"blur",
          required:true,
          message:"请选择工作经验"
        },
        education:{
          trigger:"blur",
          required:true,
          message:"请选择学历"
        },
        provinceCode:{
          trigger:"blur",
          required:true,
          message:"请选择工作城市"
        },
        address:{
          trigger:"blur",
          required:true,
          message:"请填写工作地点"
        },
        salLow:{
          required:true,
          validator(rule,value,callback){
            if(that.info.salLow === 0 || that.info.salLow === 0 || that.info.salLow === ""){
              callback("请填入薪资下限");
            }else if(that.info.salHigh === null || that.info.salHigh === 0 || that.info.salHigh === ""){
              callback("请填入薪资上限");
            }else if(that.info.salLow > that.info.salHigh){
              callback("薪资下限不能高于薪资上限");
            }else if(that.monthSalary && (that.info.salMonths === null || that.info.salMonths === 0 || that.info.salMonths === "")){
              callback("请填入薪资总月数");
            }
            callback();
          }
        }
      },
      position:[],
      positionProp:{
        lazy:true,
        lazyLoad(node,resolve){
          const {level} = node;
          let nodes = [];
          if(level === 0){
            that.allPositionList.map(v=>{
              if(v.parentId === 0 || v.parentId === '0'){
                nodes.push({
                  label:v.name,
                  value:v.id,
                  leaf:false
                });
              }
            });
            resolve(nodes);
          }else{
            that.allPositionList.map(v=>{
              if(v.parentId === node.data.value){
                nodes.push({
                  label:v.name,
                  value:v.id,
                  leaf:true
                });
              }
            });
            resolve(nodes);
          }
        }
      },
      allPositionList:[],
      loadComplete:false,
      experienceList:[],
      areaProp:{
        lazy:true,
        lazyLoad(node,resolve){
          const {level} = node;
          let nodes = [];
          if(level === 0){
            getProvinceList().then(res => {
              res.data.forEach((v)=>{
                nodes.push({
                  label:v.provinceName,
                  value:v.provinceCode,
                  leaf:false
                });
              })
              resolve(nodes);
            });
          }else if(level === 1){
            getCityList({
              provinceCode:node.data.value
            }).then(res => {
              res.data.forEach(v => {
                nodes.push({
                  label:v.cityName,
                  value:v.cityCode,
                  leaf:false
                });
              })
              resolve(nodes);
            });
          }else if(level === 2){
            getAreaList({
              cityCode:node.data.value
            }).then(res => {
              res.data.forEach(v => {
                nodes.push({
                  label:v.areaName,
                  value:v.areaCode,
                  leaf:true
                });
              });
              resolve(nodes);
            });
          }
        }
      },
    }
  },
  methods:{
    op(act){
      if(act === "save"){
        if(this.info.agreeServiceAgreement === 0 || this.info.agreeServiceAgreement === 2){
          this.$message({
            type:"error",
            message:"请勾选同意协议"
          });
          return ;
        }
        this.info.keyWords = this.keywords.join(" ");
        this.info.postClassificationId = this.position.length>0?this.position[this.position.length -1]:'';
        if(this.cityCode.length>0){
          [this.info.provinceCode,this.info.cityCode,this.info.areaCode] = this.cityCode;
        }

        this.$refs.form.validate((valid)=>{
           if(valid){
             if(this.$route.query.id !== undefined){
               if(this.writtenSalary){
                 this.info.salType = this.monthSalary?1:3;
               }else{
                 this.info.salType = 2;
               }
               this.info.companyPostId = this.$route.query.id;
               this.info.postDescription = UE.getEditor("editor").getContent();
               updateCompanyPost(this.info).then(res => {
                 if(res.code === 0){
                   this.$router.push("/myPosition");
                 }else{
                   this.$message({
                     type:"error",
                     message:res.msg
                   });
                 }
               });
             }else{
               publishCompanyPost(this.info).then(res => {
                 if(res.code === 0){
                   this.$router.push("/myPosition");
                 }else{
                   this.$message({
                     type:"error",
                     message:res.msg
                   });
                 }
               });
             }

           }
        });
      }else if(act === "cancel"){
        this.$router.push("/myPosition");
      }
    },
    delTag(){
      if(this.selectedTag>-1){
        this.keywords.splice(this.selectedTag,1);
        this.closeTag();
      }
    },
    closeTag(){
      this.showEditTag = false;
      this.tag = "";
      this.selectedTag = -1;
    },
    editTag(index){
      this.selectedTag = index;
      this.showEditTag = true;
      if(index>-1){
        this.tag = this.keywords[index];
      }
    },
    editTagSure(){
      if(this.tag.trim() === ""){
        this.$message({
          type:"error",
          message:"标签不能为空"
        });
        return ;
      }
      if(this.selectedTag>-1){
        this.keywords[this.selectedTag] = this.tag;
      }else{
        this.keywords.push(this.tag);
      }

      this.closeTag();
    },
    getPostDetail(){
      getPostDetail({
        companyPostId:this.$route.query.id
      }).then(res => {
        if(res.code === 0){
          this.cityCode = [res.data.provinceCode,res.data.cityCode,res.data.areaCode];
          this.keywords = keywordSplit(res.data.keyWords);
          res.data.workExperienceId = res.data.workExperience.id;
          this.writtenSalary = res.data.salType !== 2;
          if(res.data.salType === 3){
            this.monthSalary = false;
          }else{
            this.monthSalary = true;
          }

          for(let i in this.allPositionList){
            if(this.allPositionList[i].id === res.data.postClassificationId){
              this.position = [this.allPositionList[i].parentId,res.data.postClassificationId];
              break;
            }
          }
          Object.assign(this.info,res.data);

          UE.getEditor("editor").setContent(this.info.postDescription);
        }
      })
    },
    submit(){
    }
  },
  watch:{
    loadComplete(v){
      if(v){
        if(this.$route.query.id){
          this.getPostDetail();
        }
        window.realEditor = UE.getEditor('editor');
        window.realEditor.ready(()=>{
          UE.getEditor("editor").setContent(this.info.postDescription);
        });
      }
    }
  },
  mounted(){
    getPositionList().then(res => {
      this.allPositionList = res.data;
      this.loadComplete=true;
    });
    getWorkExperienceList().then(res => {
      if(res.code === 0){
        this.experienceList = res.data;
      }
    });
  },
  beforeUnmount() {
    UE.getEditor('editor').destroy();
  },
  computed:{
    ...mapStores(useUserStore)
  },
  components:{
    // VueEditorWrap
  }
}
</script>

<style lang="less">
@import "../../assets/css/variable";
.page_edit_position{
  .el-form{
    display:flex;
    .el-form-item__label{
      width:90px;justify-content: flex-start;
    }
    .el-switch__core .el-switch__inner .is-text{
      font-size:14px;
    }
    .form_left{
      flex:1
    }
    .form_right{
      flex:1;
      margin-left:50px;
      .salary_low,.salary_high{
        flex:1;
      }
      .salary_month{
        width:90px;
      }
      .job_tag{
        border: 1px solid #f5f2f2;
        text-align: center;
        font-size: 14px;
        height: 40px;
        line-height:40px;
        border-radius: 20px;
        padding:0 20px;
        color: #1a1a1a;
        margin-top:15px;margin-right:15px;
        margin-bottom:15px;
        cursor:pointer;
      }
      .job_tag_add{
        color:@blue_color;
        width:60px;
      }

    }
    .form_title{
      color:@blue_color;
      font-size: 18px;
      margin-bottom:20px;
    }
  }
  .agree{
    font-size: 12px;
    color: #cccccc;
    margin: 80px auto 45px;
    position:relative;
    .el-checkbox{
      height:14px;
      position:absolute;
      left:0;top:2px;
    }
    a{
      text-decoration: none;
      color:#1a1a1a;
    }
  }
}

</style>
